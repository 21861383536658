<template>
  <section id="section9" class="archive">
    <h2 class="center title">アーカイブ</h2>
    <a
      class="archive-banner"
      href="https://be-fes.bessho-onsen.com/archive/"
      target="_blank"
    >
      <img src="@/assets/img/banner1.jpg" alt="" />
    </a>
  </section>
</template>

<script>
export default {
  name: "ArchiveFes",
};
</script>

<style lang="scss">
.archive {
  background-color: #f7f7f7;
  margin-bottom: 0;
  padding-bottom: 8rem;
}
.archive-banner {
  position: relative;
  display: block;
  width: #{"min(60%, 600px)"};
  margin: 0 auto;
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
  &:hover::after {
    background: rgba(#fff, 0.2);
  }
  > img {
    width: 100%;
    height: auto;
    vertical-align: bottom;
  }
}
</style>
