<template>
  <div class="top">
    <div class="top-bg"></div>
    <main>
      <MainImg />
      <CatchCopy />
      <ArtistList />
      <MainMovie />
      <ProgramList />
      <ScheduleList />
      <EventTicket />
      <EventMap />
      <OrganizerCredit />
      <ArchiveFes />
    </main>
    <SiteFooter />
    <SiteHeader />
  </div>
</template>

<script>
import MainImg from "@/components/MainImg.vue";
import CatchCopy from "@/components/CatchCopy.vue";
import ArtistList from "@/components/ArtistList.vue";
import MainMovie from "@/components/MainMovie.vue";
import ProgramList from "@/components/ProgramList.vue";
import ScheduleList from "@/components/ScheduleList.vue";
import EventTicket from "@/components/EventTicket.vue";
import EventMap from "@/components/EventMap.vue";
import OrganizerCredit from "@/components/OrganizerCredit.vue";
import ArchiveFes from "@/components/ArchiveFes.vue";
import SiteFooter from "@/components/SiteFooter.vue";
import SiteHeader from "@/components/SiteHeader.vue";
export default {
  name: "SiteTop",
  components: {
    MainImg,
    CatchCopy,
    ArtistList,
    MainMovie,
    ProgramList,
    ScheduleList,
    EventTicket,
    EventMap,
    OrganizerCredit,
    ArchiveFes,
    SiteFooter,
    SiteHeader,
  },
};
</script>

<style lang="scss">
.top {
  position: relative;
}
.top-bg {
  position: fixed;
  width: 100%;
  height: 100vh;
  background-image: url("@/assets/img/top_bg.png");
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: -4;
  &::after {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    background: rgba(#fff, 0.95);
  }
}
main {
  position: relative;
}
.mainimg img {
  width: 100vw;
}
section {
  margin: 5rem 0;
  padding: 3rem 0;
  @include sp {
    margin: 1rem 0;
    padding: 1rem 0;
  }
}
.gray-back {
  background-color: #f4f4f4;
}
</style>
