<template>
  <section id="section5" class="ticket">
    <div class="ticket-main pc">
      <h2 class="center title">メインイベントチケット</h2>
      <div class="container center">
        <a
          href="https://eplus.jp/sf/detail/3883010001-P0030001"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src="@/assets/img/ticket01.png" alt="チケット" />
        </a>
      </div>

      <div class="container center">
        <img src="@/assets/img/ticket03.png" alt="チケット" />
      </div>
    </div>
    <div class="ticket-main sp">
      <h2 class="center title">メインイベントチケット</h2>
      <div class="container center">
        <a
          href="https://eplus.jp/sf/detail/3883010001-P0030001"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src="@/assets/img/ticket-sp01.png" alt="チケット" />
        </a>
      </div>

      <div class="container center">
        <img src="@/assets/img/ticket-sp03.png" alt="チケット" />
      </div>
    </div>
    <div class="container center ticket-sub">
      <p>
        ※小学生以下は無料。保護者同伴でお願いいたします。<br />
        ※チケットは上記購入ボタンよりご購入いただけます。<br />
        ※当日券はイベント会場にて現金のみの対応とさせていただきます。<br />
        (定員に達し次第事前もしくは当日に締め切る場合があります)
      </p>
    </div>
  </section>
</template>

<script>
export default {
  name: "EventTicket",
};
</script>

<style lang="scss" scoped>
.ticket {
  padding: 0;
  margin-top: 0;
  margin-bottom: 0;
}
.ticket-main {
  /* background-image: url(../img/yellow.png); */
  background-color: #fff282;
  background-size: cover;
  padding: 3rem;
  @include sp {
    padding: 2rem 0;
  }
  .container {
    @include sp {
      padding: 0;
    }
  }
  div {
    @include sp {
      margin-bottom: 0;
    }
  }
}

.ticket-sub {
  margin-bottom: 0;
  background-color: #fdbf02;
  padding-top: 2rem;
  padding-bottom: 2rem;
  padding-left: 20%;
  width: 100%;
  max-width: 100%;
  @include sp {
    padding-left: 2rem;
    padding-top: 4rem;
  }
  p {
    text-align: left;
  }
}
</style>
