<template>
  <section id="section3" class="program">
    <h2 class="center title">プログラム紹介</h2>
    <div class="container pc">
      <template v-for="mainProgram in mainPrograms" :key="mainProgram.id">
        <div
          v-for="program in mainProgram.column1"
          :key="program.id"
          class="row flow program-main"
        >
          <div class="col span-3">
            <img :src="program.img" alt="プログラム" />
          </div>
          <div class="col span-9 program-text">
            <h3>{{ program.ttl }}</h3>
            <p v-html="program.date"></p>
            <p v-html="program.txt"></p>
          </div>
        </div>
        <div
          v-for="program in mainProgram.column2"
          :key="program.id"
          class="row flow program-main"
        >
          <div class="col span-9 program-text">
            <h3>
              {{ program.ttl }}
            </h3>
            <p v-html="program.date"></p>
            <p v-html="program.txt"></p>
          </div>
          <div class="col span-3">
            <img :src="program.img" alt="プログラム" />
          </div>
        </div>
      </template>
      <template v-for="row in Rows" :key="row.id">
        <div class="row program-sub">
          <template v-for="r in row" :key="r.id">
            <div class="col span-4">
              <img :src="r.img" alt="プログラム" />
              <h3>{{ r.ttl }}</h3>
              <p v-html="r.date"></p>
              <p v-html="r.txt"></p>
              <a
                v-if="r.btnClass"
                :class="r.btnClass"
                :href="r.href"
                target="_blank"
              >
                <span class="form-bg"></span>
                <span class="form-txt">{{ r.btnTxt }}</span>
              </a>
            </div>
          </template>
        </div>
      </template>
    </div>

    <div class="container sp">
      <template v-for="mainProgram in mainPrograms" :key="mainProgram.id">
        <div
          v-for="program in mainProgram.column1"
          :key="program.id"
          class="row flow program-main"
          :id="program.num"
        >
          <div class="col span-3">
            <img :src="program.img" alt="プログラム" />
          </div>
          <div class="col span-9 program-text">
            <h3>{{ program.ttl }}</h3>
            <p v-html="program.date"></p>
            <p v-html="program.txt"></p>
          </div>
        </div>
        <div
          v-for="program in mainProgram.column2"
          :key="program.id"
          class="row flow program-main"
          :id="program.num"
        >
          <div class="col span-3">
            <img :src="program.img" alt="プログラム" />
          </div>
          <div class="col span-9 program-text">
            <h3>{{ program.ttl }}</h3>
            <p v-html="program.date"></p>
            <p v-html="program.txt"></p>
          </div>
        </div>
      </template>
      <template v-for="row in Rows" :key="row.id">
        <div class="row program-sub">
          <template v-for="r in row" :key="r.id">
            <div class="col span-4" :id="r.num">
              <img :src="r.img" alt="プログラム" />
              <div class="col span-9 program-text">
                <h3>{{ r.ttl }}</h3>
                <p v-html="r.date"></p>
                <p v-html="r.txt"></p>
              </div>
              <a
                v-if="r.btnClass"
                :class="r.btnClass"
                :href="r.href"
                target="_blank"
              >
                <span class="form-bg"></span>
                <span class="form-txt">{{ r.btnTxt }}</span>
              </a>
            </div>
          </template>
        </div>
      </template>
    </div>
  </section>
</template>

<script>
import { ref, computed } from "vue";
export default {
  name: "ProgramList",
  setup() {
    const mainPrograms = ref([
      {
        column1: [
          {
            num: "program1",
            img: require("@/assets/img/program01.jpg"),
            ttl: "1部「MATSURI」(殺陣、日本舞踊、ダンス、音楽)",
            date: "9/18(月祝) ・ 17:30-18:00 ・ 別所神社",
            txt: "殺陣・日本舞踊・ダンス・和楽器・洋楽器が入り交じる、時代も場所も越えたノンバーバル・エンターテイメント。無知識・異言語でも楽しめる、次世代へ繋ぐ特異な形のスペクタクル・アートステージ。<br /><br />出演：JAPAN THEATER(穴井 豪、SADA、竹本洋平、花ノ本以津輝 、MariNa、三木崇富美、山中裕史)<br />舞台、照明、音響：株式会社長野三光",
          },
        ],
        column2: [
          {
            num: "program2",
            img: require("@/assets/img/program02.jpg"),
            ttl: "2部「From ZERO -Side B-」(総合コラボレーション)",
            date: "9/18(月祝) ・ 18:30-19:30 ・ 別所神社",
            txt: "別所温泉の風土や歴史と様々なジャンルの芸術が織り成す総合コラボレーション作品。今作は昨年の「From ZERO」の続編であると共に裏側のB面を描く。底知れずうごめく人々の内面、その生まれる”熱”に焦点を絞った。<br /><br />演出、振付：山本 裕<br />ダンス：山之口理香子、NEphRiTE dance company(小倉藍歌、塚本実夢、土田貴好、中林 柚、文屋七彩、吉倉咲季)、川村真奈、若羽幸平、米澤一平、山本 裕<br />ライブペイント：越ちひろ<br />演奏：植川 縁、小出稚子<br />演奏、舞踊：JAPAN THEATER(穴井 豪、SADA、竹本洋平、花ノ本以津輝 、MariNa、三木崇富美、山中裕史)<br />舞台、照明、音響：株式会社長野三光",
          },
        ],
      },
    ]);
    const subPrograms = ref([
      {
        num: "program3",
        img: require("@/assets/img/program03.jpg"),
        ttl: "【オープニングセレモニー】(トーク、パフォーマンス)",
        date: "9/15(金) ・ 14:00-14:30 ・ 北向観音境内",
        txt: "画家の越ちひろとダンスの山本 裕が繰り広げる芸術祭やその作品にまつわるトーク。そして芸術祭を彩るダンス、音楽などのパフォーマンス作品をオムニバスにてご紹介いたします。<br /><br />トーク：越ちひろ、山本 裕<br />パフォーマンス：植川 縁、榎本結衣、川村真奈、小出稚子、土田貴好、山之口理香子、米澤一平、若羽幸平",
      },
      {
        num: "program4",
        img: require("@/assets/img/program04.jpg"),
        ttl: "《植川　縁✕米澤一平✕土田貴好「Little Child Runnin'」》(サクソフォン✕タップ✕ダンス)",
        date: "9/15(金) ・ 19:00-19:15(会場OPEN18:00-22:00) ・ 古民家カフェSmooth & Living<br />※サブスク会員500円、一般1,500円(ワンドリンク、軽食あり)",
        txt: 'サックス/息、ダンス/身体、タップ/床。<br />20分間、じぶんの身体と、空間に"ある"気配を感じよう。"なにか"を想像してる時間って、ゆ、ゆ、湯‥、豊か、だなあ、、、。<br /><br />サクソフォン：植川 縁<br />タップ：米澤一平<br />ダンス：土田貴好',
      },
      {
        num: "program5",
        img: require("@/assets/img/program05.jpg"),
        ttl: "《山之口理香子「残夢」》(ダンス)",
        date: "9/16(土) ・ 10:45-11:00 ・ 別所温泉駅",
        txt: '舞踊家・山之口理香子と電子楽器"Qコード"のセッション。<br />"Qコード"とは鈴木楽器によって制作された、即興演奏に特化したデジタルギター。<br /><br />振付、ダンス：山之口理香子<br />Qコード：sin',
      },
      {
        num: "program6",
        img: require("@/assets/img/program06.jpg"),
        ttl: "《榎本結衣「4999」》(ダンス)",
        date: "9/16(土) ・ 14:00-14:15 ・ 安楽寺庭園",
        txt: "3年前に母を亡くした。<br />記憶は離れ漂い、体の奥底で喪失感に溺れる。<br /><br />ウミガメを見て、私は母の死を思った。<br />子ガメは捕食者に襲われ、生存率は5000匹に1匹、0.02%である。<br />私の記憶は死んだ子ガメ4999匹のようだ。<br />いつか私の元に0.02%の記憶が漂着するかもしれないし、このまま安らかに広大な忘却の海を漂流し続けるのかもしれない。<br /><br />廃棄されたモノが再び押し寄せたとき、生と死の意識が浮かび上がる。<br /><br />振付、ダンス：榎本結衣",
      },
      {
        num: "program7",
        img: require("@/assets/img/program07.jpg"),
        ttl: "《若羽幸平「なにものにもなれなかったものたちへ」》(舞踏)",
        date: "9/16(土) ・ 14:15-14:30 ・ 安楽寺庭園",
        txt: "私と、私でないものと分ける境界はどこだろう？その境界をつぶさに検めて、くっきりと線を引く。<br />果たしてそれは私の形をしているだろうか？<br />腕は何本？爪は何色？翼が生えていないか？<br />ひとつひとつ、私になれなかったもの。<br />あなたもがたも、おまえらも。その裡に、なにものにもなれないなにかを潜ませていることだろう。<br /><br />振付：若羽幸平<br />舞踏：川村真奈、若羽幸平",
      },
      {
        num: "program8",
        img: require("@/assets/img/program08.jpg"),
        ttl: "【越ちひろ絵画ワークショップ ～キラキラTシャツを描こう～】(絵画)",
        date: "9/17(日) ・ 11:00-12:30 ・ 別所温泉センター",
        txt: "誰でも簡単に、世界に１つだけのキラキラTシャツを制作できるワークショップです。各回20名限定。越オリジナルステンシルシート(型紙)とスポンジペンを使い、キラキラした形をポンポンとスタンプのように色を重ね描いていきます。<br /><br />講師：越ちひろ",
        btnClass: "form",
        href: "https://forms.gle/dJeavmy24NDwZA9r9",
        btnTxt: "事前申込はこちらから",
      },
      {
        num: "program9",
        img: require("@/assets/img/program09.jpg"),
        ttl: "【別所温泉ちんどん巡り】(音楽、パレード)",
        date: "9/17(日) ・ 14:00-14:30 ・ 別所温泉エリア",
        txt: "国内外で活躍する音楽家の小出稚子、植川 縁と地域住民とのコラボレーション。ちんどん音楽隊に扮し、別所温泉エリアを練り歩きます。<br /><br />サクソフォン：植川 縁<br />鍵盤ハーモニカ：小出稚子",
      },
      {
        num: "program10",
        img: require("@/assets/img/program10.jpg"),
        ttl: "【寺村サチコ「7creatures」】(インスタレーション)",
        date: "9/10(日)-9/18(月祝) ・ 常設展示 ・ 南條旅館ロビー",
        txt: "「七苦離の湯」と言われる別所温泉。７つの苦しみから逃れることができるという。温泉に浸かった人々から逃げ出していった７つの苦しみたちを、この度捕獲することに成功。標本のように目に見えるかたちにして展示します。<br />※7苦は、生、老、病、死、愛する人との別れ、憎しみ恨みあうこと、手に入らない欲と解釈しています。",
      },
      {
        num: "program11",
        img: require("@/assets/img/program11.jpg"),
        ttl: "【奈奈子「KON↔︎JAKU」】(写真)",
        date: "9/10(日)-9/18(月祝) ・ 常設展示 ・ 大門通り",
        txt: "タイトルの「KON↔︎JAKU」は、今と昔を行き来する旅をイメージし選び、良き古き歴史を持つ別所温泉を舞台に、「写真」を通して何を伝えられるのかというのが私の課題となった。過去を復元はできないけれど、その喪失を埋めるほど美しく力強い想像力を人は持っている。これは想像力の復元の実験と言えるかもしれない。",
      },
    ]);
    const Rows = computed(() => {
      const result = [];
      for (let i = 0; i < subPrograms.value.length; i += 2) {
        result.push(subPrograms.value.slice(i, i + 2));
      }
      return result;
    });
    return {
      mainPrograms,
      subPrograms,
      Rows,
    };
  },
};
</script>

<style lang="scss" scoped>
.program {
  margin-top: 0;
  margin-bottom: 0;
  padding-bottom: 8rem;
  background: #fff;
  // background-image: url("@/assets/img/program-bg3.jpg");
  // background-size: cover;
  @include sp {
    padding-bottom: 2rem;
  }
}
.program .container {
  @include sp {
    padding: 0;
  }
}
.container .row .program-text {
  max-width: 50%;
  padding: 2rem;
  @include sp {
    max-width: 100%;
    padding: 0rem 2rem 2rem;
    line-height: 1.3;
    text-align: left;
  }
}

.program .container .program-main {
  justify-content: space-between;
}

.program .container .row {
  justify-content: space-evenly;
}

.program-text h3,
.program-sub h3 {
  font-weight: 600;
  @include sp {
    font-size: 2.3rem;
  }
}

.row .col.span-3 {
  flex: 0 0 50%;
  max-width: 75%;
  margin-bottom: 8rem;
  @include sp {
    max-width: 100%;
    margin-bottom: 1rem;
    text-align: center;
  }
}

.container .program-sub:last-child {
  &::after {
    content: "";
    display: block;
    width: 45%;
    margin-left: 4%;
  }
}

.container .program-sub .col {
  flex: 0 0 45%;
  max-width: 45%;
  margin-bottom: 8rem;
  @include sp {
    max-width: 100%;
    margin-bottom: 1rem;
  }
}

.program-sub:last-child > .col.span-4 {
  margin-bottom: 0;
}

.program-sub img {
  margin-bottom: 1rem;
}

.program .container .program-sub .program-last {
  margin-bottom: 0;
}

.program-name {
  margin-bottom: 0;
}

@property --angle {
  syntax: "<angle>";
  inherits: false;
  initial-value: 0deg;
}

.form {
  position: relative;
  display: block;
  width: 100%;
  max-width: 250px;
  height: 50px;
  padding: 2px;
  margin: 0 auto;
  margin-top: 1em;
  overflow-wrap: anywhere;
  background: linear-gradient(120deg, #f094fb, #f5576c);
  border-radius: 25px;
  @include sp {
    margin-bottom: 30px;
  }
  .form-bg {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    background: conic-gradient(
      from var(--angle),
      #f094fb,
      #f5576c,
      #f094fb,
      #f5576c,
      #f094fb
    );
    border-radius: 25px;
    opacity: 0;
    animation: gradient-spin 3s linear 0s infinite;
  }

  @keyframes gradient-spin {
    0% {
      --angle: 0deg;
    }

    100% {
      --angle: 360deg;
    }
  }
  .form-txt {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    color: #000;
    background-color: #fff;
    border-radius: 25px;
  }
}

@media (any-hover: hover) {
  .form {
    transition: opacity 0.3s;
    .form-bg {
      transition: opacity 0.3s;
    }
    &:hover {
      background-color: #f5576c;
      .form-bg {
        opacity: 1;
      }
    }
  }
}
</style>
