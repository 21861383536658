<template>
  <section id="section2" class="red">
    <h2 class="center title">アーティスト</h2>
    <div class="container center pc">
      <template v-for="artist in pcArtists" :key="artist.id">
        <div class="row">
          <template v-for="i in artist" :key="i.id">
            <div class="col span-4 artist">
              <img :src="i.img" alt="アーティスト" />
              <div class="artist-text">
                <h3>{{ i.name }}</h3>
                <p>{{ i.job }}</p>
                <p v-html="i.content"></p>
              </div>
            </div>
          </template>
        </div>
      </template>
    </div>

    <div class="sp">
      <p class="sp-artist-text">詳細は画像をタッチしてください</p>
      <template v-for="artist in spArtists" :key="artist.id">
        <div class="sp-flex">
          <template v-for="i in artist" :key="i.id">
            <div class="sp-artist">
              <input type="checkbox" :id="i.num" />
              <label id="popup" :for="i.num" class="overlay">
                <label class="popWindow">
                  <label class="close" :for="i.num">×</label
                  ><img
                    itemprop="image"
                    loading="lazy"
                    :src="i.img"
                    alt="アーティスト"
                    width="300"
                    height="200"
                  />
                  <h3>{{ i.name }}</h3>
                  <p>{{ i.job }}</p>
                  <p v-html="i.content"></p>
                </label>
              </label>
              <div class="c">
                <label :for="i.num">
                  <img
                    itemprop="image"
                    loading="lazy"
                    :src="i.img"
                    alt=""
                    width="300"
                    height="200"
                  />
                  <p class="artist-name">{{ i.name }}</p>
                  <p>{{ i.job }}</p>
                </label>
              </div>
            </div>
          </template>
        </div>
      </template>
    </div>
    <figure class="artist-bg">
      <img src="@/assets/img/artist-bg2.png" alt="" />
    </figure>
  </section>
</template>

<script>
import { ref, computed } from "vue";
export default {
  name: "ArtistList",
  setup() {
    const artists = ref([
      {
        num: "pop-up1",
        img: require("@/assets/img/artist01.jpg"),
        name: "植川 縁",
        job: "音楽",
        content:
          "フランス国立リヨン地方音楽院を審査員満場一致の1等賞で卒業後、アムステルダム音楽院にて修士課程修了。ベルギー政府給費奨学生としてゲント音楽院現代音楽上級修士課程修了。ベルギーの現代音楽アンサンブルictusとspectraのアカデミーに所属（2012-2014)。日本ジュニア管打楽器コンクール第1位、キエリインターナショナルコンペティションファイナリスト。クラシック・現代音楽の演奏活動と並行し、即興演奏家としてワークショップやライブ出演を行っている。国内外のガムラン演奏会にサックス奏者／ガムラン奏者として出演。オランダのガムランアンサンブルWidosariメンバー。日本音楽即興学会会員。",
      },
      {
        num: "pop-up2",
        img: require("@/assets/img/artist02.jpg"),
        name: "榎本結衣",
        job: "ダンス",
        content:
          "12歳より創作ダンスを始め、お茶の水女子大学舞踊教育学コースに進学。在学中、韓国芸術総合学校舞踊院の研修や梨花女子大学での留学を経て、韓国のコンテンポラリーダンスを学ぶ。<br />個人的な経験・感覚に着想を得ながら社会に開いた主題を取り扱った作品創作を目指して、ソロでの活動を開始。自身の表現において、妖麗さを持ちながらも力強い動きを得意とする。今作が自身初のソロ作品となる。<br />過去に参加した作品は鈴木ユキオ、能美健志、キム・ナムジン、中村蓉、田村興一郎 等。舞台活動と並行してメディアへも活動の幅を広げ、Eテレ『みいつけた！ステージでショー』（愛知・大阪）、LOEWE｜ロヱベ日本展開50周年記念ムービーに出演。",
      },
      {
        num: "pop-up3",
        img: require("@/assets/img/artist03.jpg"),
        name: "川村真奈",
        job: "ダンス",
        content:
          "ダンサー・振付家。藤井信子、川村泉に師事。ドイツのfolkwang university of artsに留学後、文化庁在外研修でNYへ。約12年ほどの海外生活の後、大駱駝艦で2年間活動。<br />2016年よりコンテンポラリーダンスと異ジャンルのアートの共演をプロデュースする「だんすのじかん」を主催。イタリアのVillapianaの国際フェスティバルにてBest Choreographerを受賞、あきた全国舞踊祭グランプリ、現代舞踊協会制定新人振付賞等、国内外のコンクールやフェスティバルでの受賞多数。",
      },
      {
        num: "pop-up4",
        img: require("@/assets/img/artist04.jpg"),
        name: "小出稚子",
        job: "音楽",
        content:
          "日本・オランダ・インドネシアで作曲とガムランを学ぶ。主な作品に「時間」をテーマにタップダンスを演奏に組み込んだ打楽器四重奏曲【Jamtic Jamtac】、東南アジアの雨季のじっとりとした湿度と、その環境に静かに埋没してゆく感覚を描いたオーケストラ作品【南の雨に耽る】、香川のソウルフード・讃岐うどんと日本三大たぬきの１匹・太三郎をテーマにしたピアノ独奏曲【うんぽこどんぽこ】などがある。<br />第17回芥川作曲賞受賞、第76回日本音楽コンクール作曲部門第2位と聴衆賞、第18回出光音楽賞等を受賞。<br />昨年はBBC Radio 3の委嘱による新作《揺籠と糸引き雨》がBBC交響楽団により世界初演された。",
      },
      {
        num: "pop-up5",
        img: require("@/assets/img/artist05.jpg"),
        name: "越ちひろ",
        job: "画家",
        content:
          "2004年トーキョーワンダーウォール賞受賞後、東京都庁での個展を機に数々の展覧会を開催。2008年活動拠点を長野に移す。<br />2016年上田市立美術館にて個展「ワンダーワンダー」開催。2018年「アート界の芥川賞」とも称されるVOCA展(上野の森美術館)に出品。スリランカでのJAPAN EXPOにて公開制作。2019年千曲市主催の個展「ミライノ色 ミライノ光 ーまちじゅうが美術館ー」開催。2022年長野善光寺御開帳にてコラボ御朱印と御守りを発表するなど、名実ともに日本を代表する現代アーティストとして注目を浴びる。<br />2022年別所温泉芸術祭出演。2023年別所温泉アンバサダー就任。",
      },
      {
        num: "pop-up6",
        img: require("@/assets/img/artist06.jpg"),
        name: "JAPAN THEATER",
        job: "殺陣/ダンス/音楽",
        content:
          "新しい時代の「ホンモノの日本」へようこそ。<br />ショーケース、映像作品、NFT作品等のステージを通じて日本の芸術と世界各国をルーツとする芸術のマッシュアップによる化学反応を用い、日本発の誰もが楽しめるエンターテイメントを産み出していくプロジェクト。",
      },
      {
        num: "pop-up7",
        img: require("@/assets/img/artist07.jpg"),
        name: "寺村サチコ",
        job: "インスタレーション",
        content:
          "兵庫県生まれ。多摩美術大学大学院修了後、群馬に拠点を移し活動を行う。<br />修了後女性や生命のもつ美しさとその裏側にある厳しさ・激しさや毒感”をテーマに、染めの伝統技法である絞り染めと型染めの技法を用いた立体造形作品によるインスタレーションを制作。舞台衣装や雑貨、アクセサリー、広告媒体等幅広く活動している。",
      },
      {
        num: "pop-up8",
        img: require("@/assets/img/artist08.jpg"),
        name: "奈奈子",
        job: "写真",
        content:
          "1981年 東京生まれ。2015年より、真眼塾生として写真作家として活動をはじめる。「物語る」ことへの追求からはじまり、「見える」ものの記録から、写真となったものの「変化」に物語性を込める作業を続けている。<br /><br />2015年 下北沢第二休憩所　個人展「HAMBAGA」<br />2016年 旧オリンパスギャラリー グループ展<br />2018年 キューピットバレー「山上コンサート」撮影担当<br />2018年 新世代『Tokyo』City Jazz Pop　fontana folle撮影担当<br />2019年 写真集 「ATCHOUM」出版",
      },
      {
        num: "pop-up9",
        img: require("@/assets/img/artist09.jpg"),
        name: "NEphRiTE dance company",
        job: "ダンス",
        content:
          "2017年より土田貴好、小倉藍歌の夫婦で新潟市秋葉区を拠点として活動を開始。2018年からはベルリンを中心にヨーロッパへも活動 範囲を広げる。2020年 World dance competition in Niigata において新潟県知事賞受賞。帰国後 は文化庁委託事業新国立劇場での公演や WITH HARAJYUKU Contemporary Dance Festival にて作品を出品。2021年よりNEphRiTE dance companyダンサーチームを編成し、現在10名のダンサーが所属。2022年、新津美術館野外円形劇場にて和太鼓やインスタレーションと共に『共鳴-月の聲 虫の聲 土の聲』を上演、同年、児童文学『モモ–ミヒャエル・エンデ』を舞踊劇化。",
      },
      {
        num: "pop-up10",
        img: require("@/assets/img/artist10.jpg"),
        name: "山之口理香子",
        job: "ダンス",
        content:
          "多摩美術大学 演劇舞踊デザイン学科 卒業。<br />文化庁制定選抜新人舞踊公演新人賞、全国舞踊コンクール現代舞踊第2位、ヨコハマコンペティション第1位、埼玉全国舞踊コンクール第2位、座間全国舞踊コンクール第1位など幼い頃より様々な賞を受賞。<br /><br />都民芸術フェスティバルや選抜新人舞踊公演、TOHO MUSICAL LAB.をはじめとした多くの舞台に出演。<br /><br />アイドルグループ「 METAMUSE 」の振付家兼メンバーとしても活動中。<br />「超歌手」大森靖子 のライブに出演。<br />また、劇作・演出家の 根本宗子 が手掛ける多数の作品に振付・出演を行う。<br /><br />近年では自身が着用するゴシック＆ロリィタブランドのイベントにも出演。",
      },
      {
        num: "pop-up11",
        img: require("@/assets/img/artist12.jpg"),
        name: "米澤一平",
        job: "タップ",
        content:
          "1989年東京生まれ。タップダンサー。自分とは異なる他の存在や知らない/新しい環境や関係がひとに及ぼす影響に興味を持ち、表現方法の異なる他者との対話、共存の可能性を表現者/鑑賞者を巻き込んだ場作りをする。2017年以降600回以上の本番を行い喫茶、BAR、お寺、商業施設、古民家、クリーニング店、空き地など様々な空間と異ジャンルのアーティストを出会わせ起こる場と対峙する。主催企画「In The Zone」vol.85「FOOTPRINTS」vol.59「横須賀のsalo」vol.6「サーカスBar野毛うっふsession」vol.68「ARTERA」vol.2「ノボリトリート」vol.2など。",
      },
      {
        num: "pop-up12",
        img: require("@/assets/img/artist13.jpg"),
        name: "若羽幸平",
        job: "ダンス",
        content:
          '2004年〜2020年まで舞踏カンパニー大駱駝艦に所属。カンパニー作品に出演のほか、宮本亜門作品「金閣寺」「耳なし芳一」唐十郎作品「海星」赤堀雅秋作品「大逆走」等に出演。2018年、大駱駝艦アトリエ壺中天公演にて自身の作品「みほどろ」を発表。作品「なにものにもなれなかったものたちへ」は、SAI Dance FestivalにてDuo 1st Prize、現代舞踊協会主催Choreographar’s concertにて審査員特別賞を受賞。Hong Kong Dance Exchange、京都嵐山芸術祭などに招致される。東京都認定ヘブンアーティスト「ネコ☆ソギ」としても活動中。<br /><a href="https://wakaba.coffee/">https://wakaba.coffee</a>',
      },
    ]);
    const pcSize = 3;
    const pcArtists = computed(() => {
      const result = [];
      for (let i = 0; i < artists.value.length; i += pcSize) {
        result.push(artists.value.slice(i, i + pcSize));
      }
      return result;
    });
    const spSize = 2;
    const spArtists = computed(() => {
      const result = [];
      for (let i = 0; i < artists.value.length; i += spSize) {
        result.push(artists.value.slice(i, i + spSize));
      }
      return result;
    });
    return {
      artists,
      pcArtists,
      spArtists,
    };
  },
};
</script>

<style lang="scss" scoped>
.red {
  position: relative;
  margin-bottom: 0;
  padding-bottom: 8rem;
  // background: #ffef99;
  background: #b0e0e2;
  // background-image: url("@/assets/img/artist-bg.jpg");
  // background-size: cover;
  .title {
    @include sp {
      margin-bottom: 0;
    }
  }
}

.container {
  z-index: 1;
}

.artist {
  background-color: white;
  margin-bottom: 8rem;
  text-align: left;
}

.artist-text {
  padding: 1.5rem;
  h3 {
    font-weight: 600;
    margin-bottom: 0;
  }
}

.sp {
  position: relative;
  z-index: 1;
}

.sp-artist-text {
  @include sp {
    text-align: center;
    margin-bottom: 4rem;
  }
}

.c {
  text-align: center;
}

.overlay {
  /* グレーの背景 */
  position: fixed;
  background: rgba(0, 0, 0, 0.2);
  transition: all 0.5s 0s ease;
  visibility: hidden;
  opacity: 0;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

@for $i from 1 through 12 {
  #pop-up#{$i} {
    display: none;
  }

  #pop-up#{$i}:checked + .overlay {
    visibility: visible;
    opacity: 1;
    z-index: 100000;
    background-color: #000000d0;
    width: 100%;
  }
}

.popWindow {
  border-radius: 5px;
  overflow: auto;
  /* スクロールバー用 */
  max-height: 95%;
  /* すき間 */
  min-width: fit-content;
  /* 横幅が広い時に白の部分が大きくならないため */
  max-width: 95%;
  /* すき間 */
  position: fixed;
  top: 50%;
  /* 中央に */
  left: 50%;
  /* 中央に */
  transform: translate(-50%, -50%);
  /* 中央に */
  z-index: 100010;
  font-size: 13px;
  line-height: 18px;
  margin: 10px auto;
  padding: 4px 10px;
  background: #fffd;
  /* 枠の色 */
  text-align: center;
  /* 文字を中央寄せ */
  transition: all 500ms ease-in-out;
}

.popWindow .ptitle {
  font-size: 15px;
  margin: 8px;
  color: #333;
}

.popWindow .close {
  position: absolute;
  top: 8px;
  right: 10px;
  transition: all 300ms;
  font-size: 24px;
  line-height: inherit;
  font-weight: bold;
  text-decoration: none;
  color: #000;
  padding: 2px;
}

.popWindow .close:hover {
  color: #06d85f;
}

.popWindow::-webkit-scrollbar-track {
  background: none;
}

.popWindow::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

.popWindow::-webkit-scrollbar-thumb {
  background: #999;
  border-radius: 5px;
}

.clickable:hover {
  cursor: pointer;
}

.c p {
  margin-bottom: 2rem;
  font-size: 11px;
}
.sp-artist .c .artist-name {
  margin-bottom: 0.25em;
  line-height: 1;
  font-size: 16px;
}
.program-name {
  margin-bottom: 0;
}

.sp-flex {
  @include sp {
    display: flex;
    justify-content: center;
  }
  // &:last-child::after {
  //   content: "";
  //   display: block;
  //   width: #{"min(100%, 300px)"};
  // }
}
.artist-bg {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100vw;
  margin: 0;
  margin-left: calc(50% - 50vw);
  > img {
    width: 100%;
    height: auto;
    vertical-align: bottom;
    opacity: 0.4;
  }
}
</style>
