<template>
  <div class="page-link">
    <transition name="fade">
      <p v-show="HeaderBtn" @click="scrollToTop" id="pagetop">
        <a href="#">TOP</a>
      </p>
    </transition>
    <transition name="fade">
      <p v-show="HeaderBtn" @click="scrollToLink" id="pageartist">
        <a href="#section2">アーティスト</a>
      </p>
    </transition>
    <transition name="fade">
      <p v-show="HeaderBtn" @click="scrollToLink" id="pageprogram">
        <a href="#section3">プログラム</a>
      </p>
    </transition>
    <transition name="fade">
      <p v-show="HeaderBtn" @click="scrollToLink" id="pageschedule">
        <a href="#section4">日程</a>
      </p>
    </transition>
    <transition name="fade">
      <p v-show="HeaderBtn" @click="scrollToLink" id="pageticket">
        <a href="#section5">チケット</a>
      </p>
    </transition>
    <transition name="fade">
      <p v-show="HeaderBtn" @click="scrollToLink" id="pagemap">
        <a href="#section6">マップ</a>
      </p>
    </transition>
  </div>
</template>

<script>
export default {
  name: "SiteFooter",
  data() {
    return {
      HeaderBtn: false,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.showHeaderBtn);
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.showHeaderBtn);
  },
  methods: {
    showHeaderBtn() {
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      if (scrollTop > 300) {
        this.HeaderBtn = true;
      } else {
        this.HeaderBtn = false;
      }
    },
    easeInOutCubic(t) {
      return t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;
    },
    scrollToTop() {
      const duration = 500;
      const start = window.pageYOffset || document.documentElement.scrollTop;
      const target = 0;
      const startTime = performance.now();

      const animateScroll = (currentTime) => {
        const timeElapsed = currentTime - startTime;
        const progress = Math.min(timeElapsed / duration, 1);
        window.scrollTo(
          0,
          start + (target - start) * this.easeInOutCubic(progress)
        );

        if (timeElapsed < duration) {
          requestAnimationFrame(animateScroll);
        }
      };

      requestAnimationFrame(animateScroll);
    },

    scrollToLink(event) {
      const targetId = event.target.getAttribute("href");
      const targetElement = document.querySelector(targetId);
      const targetOffset = targetElement.getBoundingClientRect().top;
      const target = targetOffset - 50;

      const duration = 500;
      const start = window.pageYOffset || document.documentElement.scrollTop;
      const startTime = performance.now();

      const animateScroll = (currentTime) => {
        const timeElapsed = currentTime - startTime;
        const progress = Math.min(timeElapsed / duration, 1);
        window.scrollTo(0, start + target * this.easeInOutCubic(progress));

        if (timeElapsed < duration) {
          requestAnimationFrame(animateScroll);
        }
      };

      requestAnimationFrame(animateScroll);
    },
  },
};
</script>

<style lang="scss" scoped>
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.5s;
}

.fade-enter-to,
.fade-leave-from {
  opacity: 1;
}

.page-link {
  display: flex;
}
#pagetop {
  position: fixed;
  bottom: 15px;
  right: 20px;
}

#pagetop a {
  width: 50px;
  display: block;
  background: #1f1f1f;
  color: #fff;
  padding: 10px 5px;
  text-align: center;
}

#pagetop a:hover {
  background: #666;
}

#pageartist {
  width: 20%;
  position: fixed;
  z-index: 2;
  top: 0;
  right: 80%;
}

#pageartist a {
  display: block;
  background: #1f1f1f;
  color: #fff;
  padding: 25px 5px;
  text-align: center;
  @include sp {
    font-size: 11px;
    padding: 25px 0px;
    font-weight: bold;
  }
}

#pageartist a:hover {
  background: #666;
}

#pageprogram {
  width: 20%;
  position: fixed;
  z-index: 2;
  top: 0;
  right: 60%;
}

#pageprogram a {
  display: block;
  background: #1f1f1f;
  color: #fff;
  padding: 25px 5px;
  text-align: center;
  @include sp {
    font-size: 11px;
    padding: 25px 0px;
    font-weight: bold;
  }
}

#pageprogram a:hover {
  background: #666;
}

#pageschedule {
  width: 20%;
  position: fixed;
  z-index: 2;
  top: 0;
  right: 40%;
}

#pageschedule a {
  display: block;
  background: #1f1f1f;
  color: #fff;
  padding: 25px 5px;
  text-align: center;
  @include sp {
    font-size: 11px;
    padding: 25px 0px;
    font-weight: bold;
  }
}

#pageschedule a:hover {
  background: #666;
}

#pageticket {
  width: 20%;
  position: fixed;
  z-index: 2;
  top: 0;
  right: 20%;
}

#pageticket a {
  display: block;
  background: #1f1f1f;
  color: #fff;
  padding: 25px 5px;
  text-align: center;
  @include sp {
    font-size: 11px;
    padding: 25px 0px;
    font-weight: bold;
  }
}

#pageticket a:hover {
  background: #666;
}

#pagemap {
  width: 20%;
  position: fixed;
  z-index: 2;
  top: 0;
  right: 0;
}

#pagemap a {
  display: block;
  background: #1f1f1f;
  color: #fff;
  padding: 25px 5px;
  text-align: center;
  @include sp {
    font-size: 11px;
    padding: 25px 0px;
    font-weight: bold;
  }
}

#pagemap a:hover {
  background: #666;
}
</style>
