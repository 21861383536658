<template>
  <div class="mainimg">
    <img src="@/assets/img/main01.png" alt="メイン画像" />
  </div>
</template>

<script>
export default {
  name: "MainImg",
};
</script>

<style lang="scss" scoped>
.mainimg {
  width: #{"min(100%, 600px)"};
  height: auto;
  margin: 0 auto;
  > img {
    width: 100%;
    height: auto;
  }
}
</style>
