<template>
  <section id="section6" class="map">
    <h2 class="center title">イベントマップ</h2>
    <div class="container center">
      <!-- <img src="@/assets/img/map01.png" alt="マップ" />
      <img src="@/assets/img/map02.png" alt="マップ" /> -->
      <img class="map-pc" src="@/assets/img/map4.png" alt="マップ" />
      <img class="map-sp" src="@/assets/img/map-sp.png" alt="マップ" />
    </div>
    <p class="map-note">
      ※1&emsp;18日(月祝)にお車でご来場の方はイベント専用駐車場をご利用ください。<br />※2&emsp;別所温泉駅でTicketQRを使用した乗降に限り運賃が無料になります。
    </p>
  </section>
</template>

<script>
export default {
  name: "EventMap",
};
</script>

<style lang="scss" scoped>
.map {
  margin-top: 0;
  margin-bottom: 0;
  padding-bottom: 8rem;
  background: #fff;
}
.map .container {
  width: #{"min(90%, 800px)"};
  margin: 0 auto;
  margin-bottom: 20px;
  @include sp {
    padding: 0rem;
  }
}
.map-pc {
  display: inline;
  @include width($width-500) {
    display: none;
  }
}
.map-sp {
  display: none;
  @include width($width-500) {
    display: inline;
  }
}
.map-note {
  width: fit-content;
  max-width: 90%;
  margin: 0 auto;
  padding: 2rem 2rem;
  color: #fff;
  background: #1f1f1f;
}
</style>
