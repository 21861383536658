<template>
  <SiteTop />
</template>

<script>
import SiteTop from "@/views/Top.vue";

export default {
  name: "App",
  components: {
    SiteTop,
  },
};
</script>

<style lang="scss">
@import "./assets/scss/ress.scss";
</style>
