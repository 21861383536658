<template>
  <div class="copyright">
    ※令和５年度 長野県地域発元気づくり支援金活用事業<br />
    Copyright ©Be-FES.Lab. All Rights Reserved.
  </div>
</template>

<script>
export default {
  name: "SiteFooter",
};
</script>

<style lang="scss" scoped>
.copyright {
  text-align: center;
  padding: 1rem 0;
  background-color: #000;
  color: #fff;
  @include sp {
    font-size: 13px;
  }
  > a {
    color: #fff;
    text-decoration: none;
    display: inline;
  }
}
</style>
