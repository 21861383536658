<template>
  <div class="catchcopy">
    <div class="catch">
      <div class="firsttext">
        <!-- <img src="@/assets/img/catch01.png" alt="キャッチコピー" /> -->
        <p>
          2023.9.15(金) − 9.18(月祝)<br />
          <span>［常設展示は9.10(日) − 9.18(月祝)］</span><br />
          in 別所温泉エリア
        </p>
      </div>
      <a href="/img/lp.pdf" target="_blank">チラシを見る ></a>
    </div>
    <div class="center">
      <img src="@/assets/img/bar.png" alt="画像" />
    </div>

    <section id="section1" class="">
      <h2 class="center about">別所温泉芸術祭 ‐ZERO‐ とは</h2>
      <div class="container center">
        <div class="pc">
          国内外で活躍するアーティストの現代的な作品と、別所温泉の<br />
          風土や歴史が交錯しながら、この地域の新しい魅力の発信を<br />
          コンセプトにこの芸術祭は立ち上げられました。<br /><br />
          芸術祭のテーマは現在を表す起点という意味を込めて<br />
          -ZERO-としました。過去が語りかけてくれるもの、そして新しい<br />
          未来への息吹。この2つを繋ぐ時間の流れの中で- ZERO-は<br />
          その確かな結び目になっていくことでしょう。<br /><br />
          この地域を訪れる旅人達、そしてこの地域を愛し生活を営む<br />
          人達の創造と交流の場として、別所温泉芸術祭- ZERO -は開幕いたします。
          <div class="artist-profile">
            <figure class="artist-profile-img">
              <img src="@/assets/img/artist11.jpg" alt="山本裕のプロフィール" />
            </figure>
            <div class="artist-profile-txt">
              <div>
                <h3>山本 裕</h3>
                <p>アートディレクター/ダンス</p>
                <p>
                  新人賞、ダンスプラン賞、全国舞踊コンクール第1位、Outstanding
                  Contemporary Choreographer Award(アメリカ)、Dance Theater
                  Prize(チェコ)など多数受賞。文化庁新進芸術家海外研修員制度でオランダのスカピーノバレエ団に1年間留学。ヨーロッパやアジアのフェスティバルより招待、ゲスト出演している。オン・ステージ新聞の新人振付家ベストに選出。振付家、ダンサーとしての実績とジャンルを超えた幅広い活躍が評価され、各地の芸術祭のアートディレクターを務める。<br /><a
                    href="https://www.yu-y.art/"
                    >https://www.yu-y.art/</a
                  >
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="sp">
          国内外で活躍するアーティストの現代的な作品と、別所温泉の
          風土や歴史が交錯しながら、この地域の新しい魅力の発信を
          コンセプトにこの芸術祭は立ち上げられました。<br /><br />
          芸術祭のテーマは現在を表す起点という意味を込めて
          -ZERO-としました。過去が語りかけてくれるもの、そして新しい
          未来への息吹。この2つを繋ぐ時間の流れの中で- ZERO-は
          その確かな結び目になっていくことでしょう。<br /><br />
          この地域を訪れる旅人達、そしてこの地域を愛し生活を営む
          人達の創造と交流の場として、別所温泉芸術祭- ZERO -は開幕いたします。
          <div class="artist-profile">
            <figure class="artist-profile-img">
              <img src="@/assets/img/artist11.jpg" alt="山本裕のプロフィール" />
            </figure>
            <div class="artist-profile-txt">
              <div>
                <h3>山本 裕</h3>
                <p>アートディレクター/ダンス</p>
                <p>
                  新人賞、ダンスプラン賞、全国舞踊コンクール第1位、Outstanding
                  Contemporary Choreographer Award(アメリカ)、Dance Theater
                  Prize(チェコ)など多数受賞。文化庁新進芸術家海外研修員制度でオランダのスカピーノバレエ団に1年間留学。ヨーロッパやアジアのフェスティバルより招待、ゲスト出演している。オン・ステージ新聞の新人振付家ベストに選出。振付家、ダンサーとしての実績とジャンルを超えた幅広い活躍が評価され、各地の芸術祭のアートディレクターを務める。<br /><a
                    href="https://www.yu-y.art/"
                    >https://www.yu-y.art/</a
                  >
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "CatchCopy",
};
</script>

<style lang="scss" scoped>
.catch {
  margin-bottom: 6rem;
  text-align: center;
  @include sp {
    margin-right: auto;
    margin-left: auto;
    max-width: 80%;
    max-width: 80%;
  }
  h2 {
    padding-bottom: 1rem;
  }
  > a {
    color: #000;
  }
}
.firsttext {
  margin-bottom: 3rem;
  @include sp {
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
  > p {
    font-size: 35px;
    font-weight: bold;
    @include sp {
      font-size: #{"clamp(1.6rem, 5vw ,2.4rem)"};
    }
    > span {
      font-size: 0.7em;
    }
  }
}
.artist-profile {
  display: flex;
  align-items: stretch;
  height: fit-content;
  margin-top: 4rem;
  border-radius: 0.5em;
  overflow: hidden;
  @include sp {
    width: 80%;
    margin: 0 auto;
    margin-top: 3em;
    flex-direction: column;
  }
}
.artist-profile-img {
  width: 100%;
  flex-basis: 30%;
  margin: 0;
  > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    vertical-align: bottom;
  }
}
.artist-profile-txt {
  display: flex;
  align-items: center;
  flex-basis: 70%;
  width: 100%;
  padding: 1.5rem;
  text-align: left;
  background: #fff;
  h3 {
    font-weight: 600;
    margin-bottom: 0;
  }
}
.opacity {
  transition: 0.3s;
  @include sp {
    width: 50%;
  }
  &:hover {
    opacity: 0.5;
  }
}
.about {
  font-weight: bold;
  font-size: 35px;
  @include sp {
    font-size: 2.3rem;
  }
}
</style>
